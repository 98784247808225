<template lang="html">
  <LegacyLayout>
    <TileList large>
      <GroupTile
        v-for="workspace in workspaces" :key="workspace.id"
        @click="$router.push({name: 'workspace', params: {workspaceId: workspace.id}})"
      >
        {{ workspace.name }}
      </GroupTile>
    </TileList>
  </LegacyLayout>
</template>

<script>
import {mapState} from 'vuex'

import LegacyLayout from '@components/nav/tiles/LegacyLayout'
import TileList from '@components/nav/tiles/TileList'
import GroupTile from '@components/nav/tiles/GroupTile'

export default {
  components: {
    LegacyLayout,
    TileList,
    GroupTile
  },
  computed: {
    ...mapState('powerBi', [
      'workspaces'
    ])
  }
}
</script>

<style lang="scss" module>

</style>
