<template lang="html">
  <div :class="{[$style.large]: large, [$style.grid]: true}">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    large: Boolean
  }
}
</script>

<style lang="scss" module>
// alias @components does not work for some reason
@use '../../breakpoints';

.grid {
  display: grid;
  grid-template-columns: repeat(var(--column-count), calc((100% - (var(--column-count) * var(--gap))) / var(--column-count)));
  grid-auto-rows: 1fr;
  gap: var(--gap);

  --column-count: 3;
  --gap: 1.25em;

  @media (min-width: breakpoints.$small-min) {
    --column-count: 4;
  }

  @media (min-width: breakpoints.$medium-min) {
    --column-count: 6;
  }

  &.large {
    --column-count: 2;

    @media (min-width: breakpoints.$small-min) {
      --column-count: 3;
    }

    @media (min-width: breakpoints.$medium-min) {
      --column-count: 4;
    }
  }

  > *:first-child {
    grid-row-start: 1;
    grid-column-start: 1;
  }

  &::before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
}
</style>
