<template lang="html">
  <div :class="$style.tile" @click="$emit('click')">
    <div :class="$style.overlay" />

    <div :class="$style.image">
      <slot name="image" />
    </div>

    <div :class="$style.content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // beta: Boolean // NOTE not yet implemented
  }
}
</script>

<style lang="scss" module>
.tile {
  background-color: var(--brand-primary);
  display: grid;
  grid-template: 1fr / 1fr;

  color: white;
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: 1px 1px 2px 0 #cbcbcb;

  cursor: pointer;
}

.overlay, .image, .content,  {
  grid-area: 1 / 1 / 2 / 2;
  height: 100%;
  width: 100%;
}

.overlay {
  pointer-events: none;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.2s;
}

.image {
  z-index: 1;
  padding: 10%;

  > * {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.content {
  z-index: 2;
  padding: 5%;

  > * {
    width: 100%;
    height: 100%;
  }
}

.tile:hover {
  box-shadow: 5px 5px 8px 2px #cbcbcb;

  .overlay {
    opacity: 0.2;
  }
}
</style>
